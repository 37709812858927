import {BaseModel, ColorModel, TaskModel} from '@core/models';
import {ConflictsModel} from '@library/core-models/src/conflicts.model';
import {ResolutionModel} from '@library/core-models/src/resolution.model';

export interface LifeSectionModel extends BaseModel {
    id?: string;
    name?: string;
    shortName?: string;
    enableNotifications?: boolean;
    enableEvaluation?: boolean;
    enableSync?: boolean;
    color?: ColorModel;
    conflicts?: ConflictsModel[];
    resolutions?: ResolutionModel[];
    tasks?: TaskModel[];
}
