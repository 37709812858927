import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {adapterFactory, CalendarCommonModule, CalendarModule as ExternalCalendarModule, DateAdapter} from 'angular-calendar';
import {CalendarViewComponent} from './calendar-view/calendar-view.component';
import {CalendarOptionsComponent} from './calendar-options/calendar-options.component';
import localeDe from '@angular/common/locales/de';
import {ContextMenuInteraction, ContextMenuService} from '@core/shared';
import {CalendarEventAddComponent} from './calendar-event-add/calendar-event-add.component';
import {FormsModule} from '@angular/forms';
import {AmazingTimePickerModule} from 'amazing-time-picker';
import {CalendarEventComponent} from './calendar-event/calendar-event.component';
import {CalendarDataSource} from 'calendar/datasources/calendar.datasource';
import {CalendarEventEditComponent} from './calendar-event-edit/calendar-event-edit.component';
import {DialogDiscardChangesComponent} from './dialog-discard-changes/dialog-discard-changes.component';
import {RScheduleConfig} from '@rschedule/rschedule';
import {MomentDateAdapter} from '@rschedule/moment-date-adapter';
import {IonicModule} from '@ionic/angular';
import {DatabaseModule} from '@core/database';
import {CalendarDayViewComponent} from 'calendar/day-view/day-view.component';
import {CalendarPouchDbDataSource} from 'calendar/datasources/calendar-pouchdb.datasource';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
RScheduleConfig.defaultDateAdapter = MomentDateAdapter;

registerLocaleData(localeDe);

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        MatGridListModule,
        MatCardModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatListModule,
        MatDividerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSlideToggleModule,
        AmazingTimePickerModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatRadioModule,
        MatCheckboxModule,
        DatabaseModule,
        CalendarCommonModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        ExternalCalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        })
    ],
    providers: [
        { provide: 'ContextMenuInteraction', useFactory: () => ContextMenuService.instance() },
    ],
    exports: [
        CalendarViewComponent,
        CalendarEventComponent,
        ExternalCalendarModule
    ],
    declarations: [
        CalendarViewComponent,
        CalendarOptionsComponent,
        CalendarEventAddComponent,
        CalendarEventComponent,
        CalendarEventEditComponent,
        DialogDiscardChangesComponent,
        CalendarDayViewComponent
    ]
})
export class CalendarModule {
    static forRoot(): ModuleWithProviders<CalendarModule> {
        return {
            ngModule: CalendarModule,
            providers: [
                { provide: CalendarDataSource, useClass: CalendarPouchDbDataSource },
            ]
        }
    }
}
