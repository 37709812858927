import {ModuleWithProviders, NgModule} from '@angular/core';
import {CoreConnectionProperties} from '@library/core-socket/src/lib/core-connection.properties';
import {CoreSocketService} from '@library/core-socket/src/lib/core-socket.service';

@NgModule({
    declarations: [],
    imports: [],
    exports: []
})
export class CoreSocketModule {
    constructor() { }

    static forRoot(config: CoreConnectionProperties): ModuleWithProviders<CoreSocketModule> {
        return {
            ngModule: CoreSocketModule,
            providers: [
                CoreSocketService,
                { provide: CoreConnectionProperties, useValue: config }
            ]
        }
    }
}
