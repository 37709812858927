import {BaseModel, ChoiceModel} from '@core/models';
import { QuestionAnswerModel } from './question-answer.model';
import { QuestionModel } from './question.model';

export interface SurveyResultModel extends BaseModel {
    surveyDate: any;
    surveyKey: any;
    surveyEvaluationType: string;
    submitDate: any;
    answers: number[];
}
