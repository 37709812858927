import {ModuleWithProviders, NgModule} from '@angular/core';
import {DatabaseRepository} from './repositories/database.repository';
import {DatabaseCollectionProvider, POUCHDB_ADAPTER} from './collections/database-collection.provider';
import {DatabaseService} from '@library/core-database/src/lib/database.service';

@NgModule({
    imports: [],
    providers: [
        DatabaseRepository,
    ]
})
export class DatabaseModule {
    static forRoot(adapter: 'cordova-sqlite' | 'idb' | string = 'idb'): ModuleWithProviders<DatabaseModule> {
        return {
            ngModule: DatabaseModule,
            providers: [
                { provide: DatabaseRepository, useClass: DatabaseRepository },
                { provide: DatabaseService, useClass: DatabaseService },
                { provide: DatabaseCollectionProvider, useClass: DatabaseCollectionProvider },
                { provide: POUCHDB_ADAPTER, useValue: adapter }
            ]
        }
    }
}
