import {BaseModel, EventTemplateModel, TimeframeEvaluationModel, TimeframeModel} from '@core/models';
import {UnorderedMapLike} from '@core/shared';

export interface LifeSectionStatsModel {
    lifeSectionId: string;

    doneTimeframeIds?: string[];
    canceledTimeframeIds?: string[];
    pendingTimeframeIds?: string[];

    totalPlannedDuration?: number;
    totalActualDuration?: number;
    totalTemplateDuration?: number;
    pendingDuration?: number;

    timeframeWellbeing?: UnorderedMapLike<number>;
    averageWellbeing?: number;

    conflictRating?: UnorderedMapLike<number>;
    averageConflictRating?: number;

    resolutionRating?: UnorderedMapLike<number>;
    averageResolutionRating?: number;
}

export interface DayStatsModel extends BaseModel {
    id?: string;
    eventTemplates: EventTemplateModel[];
    timeframeEvaluations: TimeframeEvaluationModel[];
    timeframes: TimeframeModel[];
    lifeSectionStats: UnorderedMapLike<LifeSectionStatsModel>;
    averageWellbeing?: number;
    averageConflictRating?: number;
    averageResolutionRating?: number;
    doneCount: number;
    canceledCount: number;
    pendingCount: number;
    pendingDuration: number;
    templateGroupIndex: number;
}
