import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {DatabaseModule} from '@core/database';
import {VarianceDurationPipe} from './variance-duration.pipe';
import {TimeframeEvaluationService} from '@library/plugin-timeframe-evaluation/src/lib/timeframe-evaluation.service';
import {ResolutionRatingComponent} from './resolution-rating/resolution-rating.component';
import {ConflictOccurrenceComponent} from './conflict-occurrence/conflict-occurrence.component';
import {ConflictSolutionRatingComponent} from './conflict-solution-rating/conflict-solution-rating.component';
import {TimeframeWellbeingComponent} from './timeframe-wellbeing/timeframe-wellbeing.component';
import {ActualDurationComponent} from './actual-duration/actual-duration.component';
import {TimeframeModule} from '@plugin/timeframe';
import {StatisticsModule} from '@plugin/statistics';
import { PluginRatingBarModule } from '@library/plugin-rating-bar/src/public_api';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PluginRatingBarModule,
        DatabaseModule,
        TimeframeModule,
        StatisticsModule
    ],
    declarations: [
        ResolutionRatingComponent,
        ConflictOccurrenceComponent,
        ConflictSolutionRatingComponent,
        TimeframeWellbeingComponent,
        ActualDurationComponent,
        VarianceDurationPipe
    ],
    exports: [
        ResolutionRatingComponent,
        ConflictOccurrenceComponent,
        ConflictSolutionRatingComponent,
        TimeframeWellbeingComponent,
        ActualDurationComponent
    ]
})
export class TimeframeEvaluationModule {
    static forRoot(): ModuleWithProviders<TimeframeEvaluationModule> {
        return {
            ngModule: TimeframeEvaluationModule,
            providers: [
                { provide: TimeframeEvaluationService, useClass: TimeframeEvaluationService },
            ]
        }
    }
}
