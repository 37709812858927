import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { Injectable } from "@angular/core";

@Injectable()
export class TooltipFormatter extends CalendarEventTitleFormatter {
    monthTooltip(event: CalendarEvent): string {
        return;
    }

    weekTooltip(event: CalendarEvent): string {
        return;
    }

    dayTooltip(event: CalendarEvent): string {
        return;
    }
}