import {EventDefinition} from 'calendar/models/event-definition.model';
import {EventEmitter, Injectable} from '@angular/core';
import {Moment} from 'moment';
import {EventInstanceProvider} from 'calendar/models/event-instance.provider';
import {Observable} from 'rxjs';
import {DatabaseRepository, DbContext} from '@core/database';

@Injectable({ providedIn: 'root' })
export class CalendarPouchDbDataSource {
    private readonly changeListener: EventEmitter<void> = new EventEmitter();
    private readonly db: DbContext<EventDefinition>;

    constructor(dbRepo: DatabaseRepository) {
        this.db = dbRepo.use<EventDefinition>('EventDefinition');
    }

    async save(event: EventDefinition): Promise<EventDefinition> {
        let result = await this.db.save(event);
        event.id = result._id;
        event._id = result._id;
        event._rev = result._rev;
        this.changeListener.emit();
        return event;
    }

    async delete(event: EventDefinition): Promise<void> {
        await this.db.remove(event);
        this.changeListener.emit();
    }

    async single(id: any): Promise<EventDefinition> {
        return await this.db.findById(id);
    }

    private async getRecurrence(start: Moment, end: Moment): Promise<EventDefinition[]> {
        let result = await this.db.find({
            selector: {
                $and: [
                    { start: { $lte: end.toISOString() } },
                    { recurrence: { $type: 'object' }}
                ]
            }
        });

        let items = [];
        for (let item of result) {
            item.id = item._id;
            let stream = new EventInstanceProvider({ start: start, end: end }).getInstances(item);
            await new Promise<void>(
                resolve => stream.subscribe(x => items.push(x), () => {}, () => resolve())
            );
        }
        return items;
    }

    async get(start: Moment, end: Moment): Promise<EventDefinition[]> {
        let result = await this.db.find({
            selector: {
                $and: [
                    { start: { $gte: start.toISOString() } },
                    { start: { $lte: end.toISOString() } },
                    { $not: { recurrence: { $type: 'object' }}}
                ]
            }
        });

        let items = [];
        for (let item of result) {
            item.id = item._id;
            let stream = new EventInstanceProvider({ start: start, end: end }).getInstances(item);
            await new Promise<void>(
                resolve => stream.subscribe(x => items.push(x), () => {}, () => resolve())
            );
        }
        return [...items, ...await this.getRecurrence(start, end)];
    }

    getChangeListener(): Observable<void> {
        return <any>this.changeListener;
    }
}
