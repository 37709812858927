import {Moment} from 'moment';
import {EventDefinition} from 'calendar/models/event-definition.model';

export interface MetaFeature<T = any> {
    feature: string;
    options?: T;
}

export interface FeatureHandler<T = any> {
    name: string;
    handle(meta: MetaFeature<T>): boolean;
}

export interface FeaturedEventProvider<T = any> {
    getEvents(start: Moment, end: Moment): Promise<EventDefinition<MetaFeature<T>>[]>;
}
