import { BaseModel } from '@core/models';
import { QuestionModel } from './question.model';

export type SurveyType = 'static' | 'dynamic';

export interface SurveyModel extends BaseModel {
    key: string;
    type: SurveyType;
    title: string;
    description: string;
    start: any;
    frequency: any;
    until: string;
    interval: number;
    questions: QuestionModel[];
    labels?: SurveyLabelsModel;
}

export interface SurveyLabelsModel {
    menu_item_title: string;
    statistic_title: string;
    statistic_description: string;
    statistic_graph_name: string;
    statistic_graph_color: string;
    statistic_max_ticks: number;
    survey_name: string;
    stateball_good_label: string;
    stateball_medium_label: string;
    stateball_bad_label: string;
    stateball_critical_label: string;
    stateball_good_max_value: number;
    stateball_medium_max_value: number;
    stateball_bad_max_value: number;
    stateball_critical_max_value: number;
}
