import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ColorPickerComponent} from './color-picker.component';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MatSnackBarModule} from '@angular/material/snack-bar';

@NgModule({
    declarations: [ColorPickerComponent],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        MatSnackBarModule,
        RouterModule.forChild([
            { path: '', component: ColorPickerComponent }
        ])
    ],
    exports: [ColorPickerComponent]
})
export class ColorPickerModule { }
