import {ModuleWithProviders, NgModule, Type} from '@angular/core';
import {Notifier} from '@library/core-validation/src/lib/notifiers';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ValidationService} from '@library/core-validation/src/lib/validation.service';

@NgModule({
    imports: [
        MatSnackBarModule
    ],
    declarations: [],
    exports: [],
})
export class ValidationModule {
    static forRoot(notifier: Type<any>): ModuleWithProviders<ValidationModule> {
        return {
            ngModule: ValidationModule,
            providers: [
                { provide: ValidationService, useClass: ValidationService },
                { provide: Notifier, useClass: notifier }
            ]
        }
    }
}
