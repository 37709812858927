export const environment = {
    production: false,
    enablePinLock: true,
    disableSync: false,
    serviceWorker: false,
    version: '1.74.0',
    backwardsCompatibility: '1.58.0',
    minNativeVersion: '1.58.0',
    databaseSchemaVersion: 1001,
    trainingMaterialsDate: '13.08.2019',
    enableSentryMonitoring: true,
    pouchDbAdapter: 'idb',
    latestPrivacyPolicy: 'privacy-policy-20220316',
    diagnosticApi: 'https://diagnostic.de-rena.de'
};
