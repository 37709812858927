import {Injectable} from '@angular/core';
import {AppSettings} from '@plugin/settings';
import {DatabaseCollectionProvider} from '@core/database';
import {RxCollection} from 'rxdb';
import {DayEvaluationModel, DayStatsModel} from '@core/models';
import {Moment} from 'moment';
import * as moment from 'moment';
import {EventDefinition} from 'calendar/models/event-definition.model';
import {FeaturedEventProvider} from 'calendar/feature-events/meta-feature';
import {InternalRouteFeature} from 'calendar/feature-events/internal-route.feature';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';
import {DayStatisticService} from '@plugin/statistics';
import { AlertController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AppRatingSurveyEventService implements FeaturedEventProvider<InternalRouteFeature> {
    private active: boolean;
    private settings: AppSettings;
    private alertService: AlertController;

    activate(settings: AppSettings, alertService: AlertController) {
        this.active = true;
        this.settings = settings;
        this.alertService = alertService;
    }

    async getEvents(start: Moment, end: Moment): Promise<EventDefinition[]> {
        if (!this.active) {
            return [];
        }

        const initTimestamp = this.settings.get('init-timestamp', 0);
        const initDate = moment.unix(initTimestamp);
        const daysSinceInit = moment().diff(initDate, 'days');
        const daysUntilSurvey = 30;

        if (daysSinceInit < daysUntilSurvey) {
            return [];
        }

        const isDismissed = this.settings.get('app-rating-survey-dismissed', false);
        const isSurveyEnabled = this.settings.get('app-rating-survey-enabled', false);

        if (isDismissed || !isSurveyEnabled) {
            return [];
        }

        return [{
                id: `${start.format('YYYYMMDD')}-app-rating-survey`,
                start: new Date(start.toISOString()),
                end: new Date(start.toISOString()),
                recurrence: null,
                title: `Feedback-Umfrage`,
                color: {
                    primary: '#ffd500',
                    secondary: '#000000'
                },
                allDay: true,
                meta: {
                    feature: 'internal-route',
                    options: {
                        route: '/final-survey',
                        useAngularRouting: true,
                        dialog: {
                            label: 'Zur Feedback-Umfrage',
                            icon: 'log-out',
                            additionalActions: [
                                {
                                    label: 'Erledigt',
                                    icon: 'checkmark',
                                    forceReload: true,
                                    handler: async () => {
                                        const dialog = await this.alertService.create({
                                            header: 'Feedback-Umfrage',
                                            message: 'Vielen Dank für Ihr Feedback, der Button wird jetzt ausgeblendet.',
                                            buttons: [
                                                {
                                                    text: 'OK',
                                                    handler: () => {
                                                        this.settings.set('app-rating-survey-dismissed', true);
                                                    }
                                                },
                                                {
                                                    text: 'Abbrechen',
                                                    role: 'cancel',
                                                }
                                            ]
                                        });
                                        dialog.present();
                                        await dialog.onDidDismiss();
                                    }
                                },
                                {
                                    label: 'Nicht mehr anzeigen',
                                    icon: 'trash',
                                    forceReload: true,
                                    handler: async () => {
                                        const dialog = await this.alertService.create({
                                            header: 'Feedback-Umfrage',
                                            message: 'Der Button wird jetzt ausgeblendet.',
                                            buttons: [
                                                {
                                                    text: 'OK',
                                                    handler: () => {
                                                        this.settings.set('app-rating-survey-dismissed', true);
                                                    }
                                                },
                                                {
                                                    text: 'Abbrechen',
                                                    role: 'cancel',
                                                }
                                            ]
                                        });
                                        dialog.present();
                                        await dialog.onDidDismiss();
                                    }
                                }
                            ],
                            showCancel: true
                        }
                    }
                }
            }
        ]
    }
}
