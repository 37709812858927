import {EventDefinition} from 'calendar/models/event-definition.model';
import {Moment} from 'moment';
import {EventEmitter, Injectable} from '@angular/core';
import * as moment from 'moment';
import {EventInstanceProvider} from 'calendar/models/event-instance.provider';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CalendarDataSource<T> {
    private readonly events: EventDefinition[];
    private readonly changeListener: EventEmitter<void> = new EventEmitter();

    constructor() {
        this.events = [null];
    }

    async save(event: EventDefinition): Promise<EventDefinition> {
        if (event.id) {
            this.events[event.id] = event;
        } else {
            event.id = this.events.length;
            this.events.push(event);
        }
        //this.changeListener.emit();
        return this.events[event.id];
    }

    async delete(event: EventDefinition): Promise<void> {
        this.events[event.id] = null;
        this.changeListener.emit();
    }

    async single(id: any): Promise<EventDefinition> {
        return this.events[id];
    }

    async get(start: Moment, end: Moment): Promise<EventDefinition[]> {
        const options = {
            start: moment(start),
            end: moment(end)
        };

        let result = this.events
            .filter(x => x !== null)
            .filter(x => moment(x.start).isAfter(start) && (!x.end || moment(x.end).isBefore(end)));

        let items = [];
        for (let item of result) {
            let stream = new EventInstanceProvider(options).getInstances(item);
            await new Promise<void>(
                resolve => stream.subscribe(x => items.push(x), () => {}, () => resolve())
            );
        }

        return items;
    }

    getChangeListener(): Observable<void> {
        return <any>this.changeListener;
    }
}
