import {BaseModel} from '@core/models';
import {TimeframeModel} from '@library/core-models/src/timeframe.model';

export interface ResolutionRating {
    rating?: number;
}

export interface ConflictRating {
    occurred?: boolean;
    solutionRating?: number;
}

export interface TimeframeEvaluationModel extends BaseModel {
    timeframeId: string;
    timeframeSnapshot: TimeframeModel;

    resolution: ResolutionRating;
    conflict: ConflictRating;
    wellbeing?: number;
    durationVariance?: number;

    eventDate: Date;
    created?: Date;
    readonly?: boolean;
}
