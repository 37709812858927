import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SurveyGuard} from './survey/services/survey.guard';

const routes: Routes = [
    { path: '', redirectTo: 'todo', pathMatch: 'full' },
    { path: 'calendar', loadChildren: () => import('./calendar/calendar-page.module').then(m => m.CalendarPageModule) },
    { path: 'calendar-event', loadChildren: () => import('./calendar-event/calendar-event-page.module').then(m => m.CalendarEventPageModule) },
    { path: 'survey', loadChildren: () => import('./survey/survey-page.module').then(m => m.SurveyPageModule), canActivate: [SurveyGuard]},
    { path: 'charts', loadChildren: () => import('./charts-example/charts-example.module').then(m => m.ChartsExampleModule)},
    { path: 'connect', loadChildren: () => import('./connect/connect-page.module').then(m => m.ConnectPageModule)},
    { path: 'life-sections', loadChildren: () => import('./life-section/life-section-page.module').then(m => m.LifeSectionPageModule)},
    { path: 'life-section', loadChildren: () => import('./life-section-edit/life-section-edit-page.module').then(m => m.LifeSectionEditPageModule)},
    { path: 'slogan-editor', loadChildren: () => import('./slogan-editor/slogan-editor.module').then(m => m.SloganEditorModule)},
    { path: 'evaluate-day', loadChildren: () => import('./day-evaluation/day-evaluation-page.module').then(m => m.DayEvaluationPageModule)},
    { path: 'evaluate/timeframe', loadChildren: () => import('./timeframe-evaluation/timeframe-evaluation-page.module').then(m => m.TimeframeEvaluationPageModule)},
    { path: 'statistics', loadChildren: () => import('./statistics/statistic-page.module').then(m => m.StatisticPageModule)},
    { path: 'todo', loadChildren: () => import('./todo/todo-page.module').then(m => m.TodoPageModule)},
    { path: 'event-templates', loadChildren: () => import('./event-templates/event-templates-page.module').then(m => m.EventTemplatesPageModule)},
    { path: 'event-template', loadChildren: () => import('./event-template-edit/event-template-edit-page.module').then(m => m.EventTemplateEditPageModule)},
    { path: 'event-template-selection', loadChildren: () => import('./event-template-selection/event-template-selection-page.module').then(m => m.EventTemplateSelectionPageModule)},
    { path: 'exercise', loadChildren:() => import('./exercise/exercise-page.module').then(m => m.ExercisePageModule)},
    { path: 'settings', loadChildren:() => import('./settings/settings.module').then(m => m.SettingsModule)},
    { path: 'form', loadChildren:() => import('./generic-form/generic-form.module').then(m => m.GenericFormModule)},
    { path: 'medical-certificate', loadChildren: () => import('./settings/medical-certificate/medical-certificate.module').then(m => m.MedicalCertificatePageModule) },
    { path: 'help-videos', loadChildren: () => import('./help-videos/help-videos.module').then(m => m.HelpVideosPageModule) },
    { path: 'tutorial', loadChildren: () => import('./app-tour-page/app-tour-page.module').then(m => m.AppTourPageModule) },
    { path: 'termination-page', loadChildren: () => import('./termination/termination.module').then(m => m.TerminationPageModule) },
    //{ path: 'final-survey', loadChildren: () => new Promise(() => { window.open('https://www.survio.com/survey/d/T5Q3N4R6W3F9X3B4M', '_system', 'location=yes') })},
    { path: 'final-survey', loadChildren: () => import('./final-survey/final-survey.module').then(m => m.FinalSurveyModule) },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
