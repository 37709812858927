import {BaseModel} from '@core/models';
import {TaskModel} from '@library/core-models/src/task.model';
import {ResolutionModel} from '@library/core-models/src/resolution.model';
import {ConflictsModel} from '@library/core-models/src/conflicts.model';

export interface TimeframeModel extends BaseModel {
    id?: string;
    lifeSectionId: any;
    eventId: any;
    start?: Date | string;
    end?: Date | string;
    resolution?: ResolutionModel;
    conflict?: ConflictsModel;
    tasks: TaskModel[];
    done?: boolean;
    canceled?: boolean;
    cancelReason?: string;
}
