import {Injectable} from '@angular/core';
import { Device, DeviceInfo } from '@capacitor/device';
import {Platform} from '@ionic/angular';
import {DeviceInterface} from '@plugin/cloud-connect/api';

@Injectable({ providedIn: 'root' })
export class DeviceService implements DeviceInterface {
    private deviceInfo: DeviceInfo;
    private deviceIdentifier: string;
    private deviceInfoTask = Promise.all([Device.getInfo(), Device.getId()]).then(([info, id]) => {
        this.deviceInfo = info;
        this.deviceIdentifier = id.identifier;
        console.log('Device info:', info, id);
    }).catch(console.error);

    constructor(private currentPlatform: Platform) { }

    get isAndroid(): boolean { return this.currentPlatform.is('android'); }

    get isIOS(): boolean { return this.currentPlatform.is('ios'); }

    get isBrowser(): boolean { return !this.currentPlatform.is('cordova') && !!navigator }

    get deviceId(): string {
        if (this.deviceIdentifier) {
            return this.deviceIdentifier;
        }
        return null;
    }

    get manufacturer(): string {
        if (this.deviceInfo) {
            return this.deviceInfo.manufacturer;
        }
        return null;
    }

    get model(): string {
        if (this.deviceInfo) {
            return this.deviceInfo.model;
        }
        return null;
    }

    get platform(): string {
        if (this.deviceInfo) {
            return this.deviceInfo.platform;
        }
        return null;
    }

    get version(): string {
        if (this.deviceInfo) {
            return this.deviceInfo.osVersion;
        }
        return null;
    }

    async complete(): Promise<void> {
        await this.deviceInfoTask;
    }

    toJSON(): DeviceInterface {
        return {
            name: this.deviceInfo.name,
            deviceId: this.deviceId,
            manufacturer: this.manufacturer,
            model: this.model,
            platform: this.platform,
            version: this.version
        }
    }
}
