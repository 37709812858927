import FindRequest = PouchDB.Find.FindRequest;
import {BaseModel} from '@core/models';
import {Observable} from 'rxjs';

// Todo: write own interface
export type FindFilter = FindRequest<{}>;

export interface DbContext<T extends BaseModel> {
    find(filter?: FindFilter): Promise<T[]>;
    findById(id: string, indexGiven?: boolean): Promise<T>;
    remove(item: T): Promise<boolean>;
    save(item: T): Promise<T>;
    upsert(item: T | any);
    exists(item: T | any | string): Promise<boolean>;
    getInternalId(item: any | T): string;
    getChangeStream(): Observable<any>;
}
