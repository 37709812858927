import {Injectable} from '@angular/core';
import {DatabaseCollectionProvider, SyncSettings} from '@core/database';
import {RxCollection} from 'rxdb';
import {DATABASE_COLLECTIONS} from '@library/core-models/src/schema';
import * as moment from 'moment';
import {DatabaseService} from '@core/database';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Platform} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {AssetManagerService} from './asset-manager.service';
import {AssetRef} from '@library/plugin-settings/src/lib/asset';

export type SettingEntry<T = any> = { key: string, value: T };

export type ClinicSettings = {
    version: number;
    properties: SettingEntry[];
    assets: AssetRef[];
};

@Injectable({
    providedIn: 'root'
})
export class AppSettings {
    private syncSettings: SyncSettings;
    private changeStream: Subject<SettingEntry>;
    private collection: RxCollection<{ key: string, value: any }>;
    private settings: { [key: string]: any } = { };
    private throttledInstructions: ((settings: AppSettings) => void)[] = [];
    private readonly settingsPromise: Promise<void>;

    ready: boolean = false;

    constructor(
        private collectionProvider: DatabaseCollectionProvider,
        private platform: Platform,
        private http: HttpClient,
        private assetMgr: AssetManagerService,
        databaseService: DatabaseService
    ) {
        this.changeStream = new Subject();
        this.settingsPromise = new Promise<void>(resolve => {
            databaseService.databaseAwaiter().then(async () => {
                await this.init();
                resolve();
            })
        });

        this.awaiter().then(() => this.runThrottledInstructions());
    }

    get endpointSettings(): SyncSettings { return this.syncSettings || { name: null, collections: [], uri: null } }

    get settingsCollection(): RxCollection<{ key: string, value: any }> {
        if (!this.collection)
            this.collection = this.collectionProvider.use(DATABASE_COLLECTIONS.SettingsCollection);
        return this.collection;
    }

    awaiter(): Promise<void> {
        return this.settingsPromise;
    }

    async runThrottledInstructions() {
        this.throttledInstructions.forEach(x => x(this));
        this.throttledInstructions = [];
        this.ready = true;
    }

    applyWhenReady(func: (settings: AppSettings) => void) {
        if (this.ready) {
            func(this)
        } else {
            this.throttledInstructions.push(func)
        }
    }

    changes<T = any>(key: string): Observable<SettingEntry> {
        return this.changeStream.asObservable().pipe(
            filter(x => x.key === key)
        )
    }

    allChanges<T = any>(startsWith: string): Observable<SettingEntry<T>[]> {
        return this.changeStream.pipe(
            filter(x => x.key.startsWith(startsWith)),
            map(x => this.getAll(startsWith))
        )
    }

    getAll<T=any>(startsWith: string): SettingEntry<T>[] {
        return Object
            .keys(this.settings)
            .filter(x => x.startsWith(startsWith))
            .map(x => ({ key: x, value: this.settings[x] }))
    }

    count(f: (e: SettingEntry) => boolean): number {
        let count = 0;
        let keys = Object.keys(this.settings);
        for (let key of keys) {
            if (f({ key: key, value: this.settings[key] })) {
                ++count;
            }
        }
        return count;
    }

    has(key: string): boolean {
        return this.settings[key] !== undefined
    }

    get<T>(key: string, defaultValue: T = null): T | any {
        console.log('settings.get', key, defaultValue);
        if ((this.settings[key] === undefined || this.settings[key] === null) && (defaultValue !== undefined || defaultValue !== null))
            this.set(key, defaultValue);
        return this.settings[key];
    }

    set<T>(key: string, value: T) {
        console.log('settings.set', key, value);
        this.settings[key] = value;
        this.changeStream.next({ key: key, value: value });
        // We need this atomic access to avoid the document update conflict that throws an Error when you
        // use the event-templates-selection from the calendar-day-view with multiple appointments
        this.settingsCollection.atomicUpsert({ key: key, value: value });
    }

    unset(key: string): boolean {
        const exists = !!this.settings[key];
        this.settings[key] = undefined;
        if (exists) {
            this.settingsCollection
                .findOne(key)
                .remove()
                .then(() => console.log('settings key unset', key))
                .catch(e => console.warn('cannot unset settings key', key, e))
        }
        return exists;
    }

    assetManager(): AssetManagerService {
        return this.assetMgr;
    }

    async init() {
        this.initDefaultEntries();
        await this.loadFromDatabase();
        this.syncSettings = await this.collectionProvider.getSyncSettings()
    }

    async loadFromDatabase() {
        let entries = (await this.settingsCollection.find().exec()).map(x => x.toJSON()) || [];
        for (let entry of entries) {
            this.settings[entry.key] = entry.value;
        }
    }

    async fetchRemoteVersion(): Promise<number> {
        const url = await this.getAppSettingsUrl();
        const result = await this.http.get(url).toPromise();
        console.log('fetch', result);
        return Number.parseInt(result['version']);
    }

    async getAppSettingsUrl(): Promise<string> {
        const domain = await this.collectionProvider.getApiDomain();
        if (domain === 'localhost')
            return `http://${domain}:3001/v2/app-settings`;
        return `https://${domain}/v2/app-settings`;
    }

    async fetchRemoteSettings(): Promise<ClinicSettings> {
        const url = await this.getAppSettingsUrl();
        const result = await this.http.get<ClinicSettings>(url).toPromise();
        console.log('fetchSettings', result);
        return result;
    }

    async fetchRemote() {
        await this.awaiter();

        const version = this.get<number>('clinic/settings/version', 0);
        const latestVersion = await this.fetchRemoteVersion();
        console.log('local-version', version, 'remote-version', latestVersion);
        if (version >= latestVersion)
            return;

        const settings = await this.fetchRemoteSettings();
        this.set<ClinicSettings>('clinic-settings', settings);

        for (let property of settings.properties) {
            this.set(property.key, property.value);
        }

        for (let asset of settings.assets) {
            await this.assetMgr.verify(asset);
        }

        this.set('clinic/settings/version', latestVersion);
    }

    toJSON(): SettingEntry[] {
        const keys = Object.keys(this.settings);
        const result: SettingEntry[] = [];
        for (let key of keys) {
            result.push({
                key: key,
                value: this.settings[key]
            })
        }
        return result;
    }

    private initDefaultEntries() {
        const defaultEntries: { key: string, value: any }[] = [
            { key: 'day-evaluation-availability-duration', value: 3 },
            { key: 'calendar-drag-scroll', value: !this.platform.is('ios') }
        ];

        for (let entry of defaultEntries) {
            this.settings[entry.key] = entry.value;
        }
    }
}
