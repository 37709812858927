import {FeaturedEventProvider, FeatureHandler, MetaFeature} from 'lib/plugin-calendar/src/lib/feature-events/meta-feature';
import {Injectable} from '@angular/core';
import {EventDefinition} from 'calendar/models/event-definition.model';
import {Moment} from 'moment';
import {InternalRouteFeature} from 'calendar/feature-events/internal-route.feature';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FeaturedEventsManagerService {
    forceDataReload$ = new Subject();

    private readonly handlers: FeatureHandler[];
    private readonly providers: FeaturedEventProvider[];

    constructor(internalRouteFeature: InternalRouteFeature) {
        this.handlers = [
            internalRouteFeature
        ];
        this.providers = [];
        internalRouteFeature.forceReload$.subscribe(() => this.forceDataReload$.next());
    }

    addHandler(handler: FeatureHandler) {
        this.handlers.push(handler);
    }

    addProvider(provider: FeaturedEventProvider) {
        this.providers.push(provider);
    }

    async getFeaturedEvents(start: Moment, end: Moment): Promise<EventDefinition<MetaFeature<any>>[]> {
        const result = [];
        const promises = this.providers.map(x => x.getEvents(start, end));
        const fetched = await Promise.all(promises);
        fetched.forEach(x => result.push(...x));
        return result;
    }

    handle(meta: MetaFeature<any>): boolean {
        let result = false;
        for (let handler of this.handlers) {
            if (!meta || !meta.feature)
                continue;

            if (meta.feature === handler.name) {
                result = handler.handle(meta) || result;
            }
        }
        return result;
    }
}
